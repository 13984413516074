<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import Loader from 'sja.vue.libraries.charts.Loader.vue';

let series = ref([]);
let categories = ref([]);
let orgunits = ref([]);
let loaded = ref(false);
let weeksBetween = ref([]);

const dsSJACreatedOrg = getOrCreateDataObject({
    id: 'dsSJACreatedOrg',
    viewName: 'aviw_SJA_SJA',
    maxRecords: -1,
    whereClause: `Workflow_Deleted IS NULL`,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields: [
        { name: "OrgUnit" },
        { name: "Created" },
        { name: "WeekCreated" },
        { name: "WeekAndYearCreated" },
        { name: "OrgUnitIdPath" },
    ]
});

dsSJACreatedOrg.enableContextFilter();

dsSJACreatedOrg.on('DataLoaded', async (data, options) => {
    if (data.length > 0) {
        categories.value = [
            ...new Set(data.map((element) => element.WeekAndYearCreated)),
        ];
        orgunits.value = [
            ...new Set(data.map((element) => element.OrgUnit)),
        ];
        weeksBetween.value = generateWeeksBetween(categories.value);
        orgunits.value.forEach((value, index) => {
            let seriesData = [];
            weeksBetween.value.forEach((weekValue, index) => {
                seriesData.push(data.filter(row => row.WeekAndYearCreated === weekValue && row.OrgUnit === value).length)
            });
            series.value.push({ name: value, data: seriesData });
        });
        await createChart();
    } else {
        series = ref([]);
        categories = ref([]);
        orgunits = ref([]);
        startWeek = ref();
        endWeek = ref();
        weeksBetween = ref([]);
        await createChart();
    }
    loaded.value = true;
});

onMounted(async () => {
    await dsSJACreatedOrg.load();
});

function getISOWeeksInYear(year) {
    const lastDayOfYear = new Date(year, 11, 31);
    const weekNumber = lastDayOfYear.getDay() === 4 || (lastDayOfYear.getDay() === 3 && lastDayOfYear.getHours() === 0) ?
        53 : 52;
    return weekNumber; 
}

function generateWeeksBetween(weeks) {
    const weeksArray = weeks.map(week => {
        const [year, weekNumber] = week.split('-').map(Number);
        return { year, weekNumber };
    });

    const minYear = Math.min(...weeksArray.map(week => week.year));
    const maxYear = Math.max(...weeksArray.map(week => week.year));

    const generatedWeeks = [];
    for (let year = minYear; year <= maxYear; year++) {
        const currentStartWeek = (year === minYear) ? Math.min(...weeksArray.filter(week => week.year === year).map(week => week.weekNumber)) : 1;
        const currentEndWeek = (year === maxYear) ? Math.max(...weeksArray.filter(week => week.year === year).map(week => week.weekNumber)) : getISOWeeksInYear(year);
        for (let week = currentStartWeek; week <= currentEndWeek; week++) {
            generatedWeeks.push(`${year}-${week}`);
        }
    }
    return generatedWeeks;
}

function createChart() {
    const chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: $t(`SJA's created per week by Org Unit`)
        },
        xAxis: {
            categories: weeksBetween.value
        },
        yAxis: {
            title: {
                text: $t('Total')
            },
            stackLabels: {
                enabled: true
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                drilldown: true
            }
        },
        series: series.value
    };

    Highcharts.chart('createdChartOrgUnitsContainer', chartOptions);
}
</script>

<template>
    <div id="createdChartOrgUnitsContainer" class="p-2" style="width: 100%">
        <Loader :loaded="loaded"></Loader>
    </div>
</template>
